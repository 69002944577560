<template>
  <div
    class="text-center"
    style="margin-top: 30%"
  >
    <div class="d-flex flex-column justify-center gap-2">
      <h1 class="justify-center mt-5">
        {{ $t('product-finder.success.title') }}
      </h1>
      <v-icon
        class="mt-5"
        color="primary"
        size="200"
      >
        mdi-check-circle
      </v-icon>
      <p class="mt-5">
        {{ $t('product-finder.success.info', {seconds}) }}
      </p>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      seconds: 4 // Initial countdown value in seconds
    }
  },
  mounted () {
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      const countdownInterval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--
        } else {
          clearInterval(countdownInterval)
          this.$router.push({ name: 'dashboard' })
        }
      }, 1000)
    }
  }
}

</script>
