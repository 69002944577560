<template>
  <div class="pa-sm-0 text-center">
    <h1 class="justify-center mx-auto px-sm-0">
      {{ $t('product-finder.website.title') }}
    </h1>
    <div class="my-4">
      {{ $t('product-finder.website.sub-title') }}
    </div>

    <div class="my-12">
      <h4 class="pb-2 text-left">
        {{ $t('product-finder.website.link-label') }}
      </h4>
      <v-form ref="website">
        <v-text-field
          :value="websiteUrl"
          class="website-input"
          outlined
          type="url"
          :placeholder="$t('product-finder.enter-website-placeholder')"
          :rules="noWebsite ? [] : [rules.url, rules.required]"
          :disabled="noWebsite"
          validate-on-blur
          @keydown.enter.prevent="proceed"
          @input="$emit('websiteUrl', $event)"
        />
      </v-form>
      <v-checkbox
        v-model="noWebsite"
        hide-details
      >
        <template v-slot:label>
          {{ $t('product-finder.website.no-website') }}
        </template>
      </v-checkbox>
    </div>

    <Button
      primary
      class="proceed w-full"
      @click="proceed"
    >
      {{ noWebsite ? $t('buttons.product-finder.proceed') : $t('buttons.product-finder.process') }}
    </Button>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import { url, required } from '@/lib/validation'
import Button from './Button.vue'

export default {
  components: { Button },
  mixins: [countries],
  props: {
    websiteUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      noWebsite: false
    }
  },
  created () {
    this.rules = { url, required }
  },
  methods: {
    proceed () {
      const valid = this.$refs.website.validate()
      if (valid) {
        this.$emit('next', this.noWebsite)
      }
    }
  }
}
</script>
