<template>
  <v-btn
    :style="cssProps"
    class="flex-grow-1"
    :class="{
      'secondary': !primary,
      'primary': primary
    }"
    elevation="0"
    x-large
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    primary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--blue': this.$vuetify.theme.themes.light.markero.blue,
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue
      }
    }
  }
}

</script>
<style scoped>
/* Secondary Button Styles */
.secondary {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border: 1px solid var(--blue) !important;
  padding: 13px 34px !important;
  background-color: white !important;
  border-radius: 10px;
  color: var(--blue) !important;
  height: fit-content !important;
  text-transform: none;
}

/* Primary Button Styles */
.primary {
  font-size: 16px;
  font-weight: 700;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
  line-height: normal;
  color: white;
  background-color: var(--blue) !important;
}
</style>
